import React from 'react';
import { FiMail, FiPhone } from 'react-icons/fi';

const Contact: React.FC = () => (
  <>
    <p>
      For further details please Contact
      <br />
      Andy or Jordan
      <br />
      Lakes Outdoor Adventure Ltd
    </p>
    <p>
      <FiPhone />
      <a href="tel:+441228 597726"> 01228 597726</a>
      <br />
      <FiMail />
      <a href="mailto:bookings.propaintball@gmail.com">
        {` `}bookings.propaintball@gmail.com
      </a>
    </p>
  </>
);

export default Contact;

import { graphql, Link, useStaticQuery } from 'gatsby';
import React from 'react';
import Img from 'gatsby-image';

const Offer: React.FC = () => {
  const query = useStaticQuery(
    graphql`
      query {
        file(relativePath: { eq: "splat.webp" }) {
          childImageSharp {
            fluid(maxWidth: 379, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
      }
    `
  );
  return (
    <div style={{ width: `100%`, margin: `0 auto` }}>
      <p>
        <Link to="/offer/">
          <Img
            style={{ display: `block`, margin: `0 auto` }}
            fluid={query.file.childImageSharp.fluid}
            alt="Offer"
          />
        </Link>
      </p>
    </div>
  );
};

export default Offer;

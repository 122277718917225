import { PageProps } from 'gatsby';
import React from 'react';
import Contact from '../components/contact';
import Offer from '../components/offer';
import Layout from '../components/layout';
import SEO from '../components/seo';

const Home: React.FC<PageProps> = () => (
  <Layout>
    <SEO title="Home" />
    <Contact />
    <Offer />
  </Layout>
);

export default Home;
